var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"call-settings-layout"}},[_c('div',{staticClass:"call-settings-layout-container"},[_c('div',{staticClass:"call-settings-navbar p-2 card"},[_c('router-link',{staticClass:"navbar-item",class:{
          active: _vm.currentRoute.startsWith(
            '/settings/call-settings/after-call'
          )
        },attrs:{"to":"/settings/call-settings/after-call"}},[_vm._v(" "+_vm._s(_vm.$t('settings.callSettings.afterCall.title'))+" ")]),_c('router-link',{staticClass:"navbar-item",class:{
          active: _vm.currentRoute.startsWith(
            '/settings/call-settings/default-survey'
          )
        },attrs:{"to":"/settings/call-settings/default-survey"}},[_vm._v(" "+_vm._s(_vm.$t('settings.callSettings.defaultSurvey.title'))+" ")])],1),_c('div',{staticClass:"flex-fill card p-3"},[_c('router-view')],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }